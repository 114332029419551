<template>
  <div class="c-w-100 c-h-100 flex-center-vertical is-relative" :class="{'z-index-4': openModal}">
    <div
      class="flex-center-vertical is-justify-content-space-between c-w-100 c-h-100"
      :class="{'is-clickable': !disabled}"
      @click="openModal = disabled ? false : true"
    >
      <div class="selected-material-name px-3 py-2">
        <p :class="[selectedNameMaterial ? 'averta-bold' : 'averta-reguler-italic color-red']">{{selectedNameMaterial || renderTitle}}</p>
      </div>
      <a class="card-header-icon p-0" v-if="!disabled">
        <b-icon
          :icon="openModal ? 'menu-up' : 'menu-down'">
        </b-icon>
      </a>
    </div>
    <div class="modal-select-material" v-if="openModal" v-click-outside="closeModal">
      <div class="mx-3 mb-1">
        <input
          type="text"
          class="mt-1 label-14 input c-w-100"
          placeholder="Cari Material"
          @input="searchMaterial"
        />
      </div>
      <div class="wrap-body-modal-materials">
        <div v-if="isLoading" class="mt-4 px-4">
          <p class="color-grey averta-reguler-italic">Loading...</p>
        </div>

        <div v-else-if="!isLoading && data.length > 0" class="data-item-wrapper">
          <div
            v-for="(item, index) in data"
            :key="`item-${index}`"
            class="flex-center-vertical py-3 pr-2 is-clickable"
            @click="() => handleSelectMaterial(item, index)"
          >
            <input
              type="checkbox"
              :checked="checkMaterial(item.id)"
              class="flex-shrink-0 is-clickable ml-3"
            >
            <div class="img-item-material mx-2 flex-shrink-0">
              <img :src="item.picture || require('@/assets/img/empty-img-2.png')" alt="img-material">
            </div>
            <p>{{ item.name }}</p>
          </div>
        </div>

        <div v-else class="has-text-centered">
          <p class="color-grey averta-reguler-italic mt-0">
            Material Tidak Ditemukan
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { alertErrorMessage, debounce, arrayToName } from '@/helpers'

export default {
  name: 'InputAutocompleteMaterials',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Object, String],
      default: null
    },
    hideBorder: {
      type: Boolean,
      default: false
    },
    unsetMaxWidth: {
      type: Boolean,
      default: false
    },
    code: {
      type: String,
      default: null
    },
    idAutocomplete: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      unitName: '',
      initData: [],
      data: [],
      openModal: false,
      selectedNameMaterial: ''
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      storeMaterials: 'unitPrice/getSelectedMaterials',
      reset: 'unitPrice/getResetUnitPrice',
      detailUnitPrice: 'unitPrice/getDetailUnitPrice'
    }),
    isMaterial () {
      return this.code === 'add_materials' || this.code === 'edit_materials'
    },
    isWorkTool () {
      return this.code === 'add_work_tools' || this.code === 'edit_work_tools'
    },
    isAuxiliaryTool () {
      return this.code === 'add_auxiliary_tools' || this.code === 'edit_auxiliary_tools'
    },
    renderTitle () {
      const title = this.isMaterial ? 'Material' : this.isWorkTool ? 'Alat Kerja' : this.isAuxiliaryTool ? 'Alat Bantu' : ''
      return 'Pilih ' + title
    }
  },
  methods: {
    handleSelectItem (option) {
      this.$emit('selectedUnit', option || 'empty')
    },
    checkMaterial (id) {
      const find = this.initData.find(item => item.id === id)
      return find.checked || false
    },
    getDataMaterials () {
      this.isLoading = true
      const payload = {
        url: this.url,
        limit: 1000
      }
      this.$store
        .dispatch('product/getListProducts', payload)
        .then((response) => {
          const res = response.data.data
          const init = JSON.parse(JSON.stringify(res))
          this.isLoading = false

          const selected = (this.value && this.value.selected) || this.storeMaterials

          if (selected.length > 0) {
            const temp = init.map((item) => {
              const index = selected.findIndex(material => Number(material.id) === item.id)
              return {
                ...item,
                checked: index > -1
              }
            })
            this.initData = JSON.parse(JSON.stringify(temp))
            this.data = temp

            this.selectedNameMaterial = arrayToName(selected)
          } else {
            this.initData = init
            this.data = res
          }
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    handleSelectMaterial (value, index) {
      const temp = {
        ...value,
        checked: value.checked ? !value.checked : true
      }
      const indexInitData = this.initData.findIndex(item => item.id === value.id)
      this.initData.splice(indexInitData, 1, temp)
      this.data.splice(index, 1, temp)

      const res = this.initData.filter(item => item.checked)
      this.$store.commit('unitPrice/setSelectedMaterials', res)
      this.handleSelectItem(res)
    },
    closeModal () {
      const res = this.initData.filter(item => item.checked)
      this.selectedNameMaterial = arrayToName(res)
      this.openModal = false
    },
    searchMaterial: debounce(function (value) {
      const search = this.initData.filter((item) => item.name.toLowerCase().includes(value.target.value.toLowerCase()))
      this.data = search
    }, 300)
  },
  mounted () {
    if (this.value && this.value !== 'empty') {
      this.selectedNameMaterial = arrayToName(this.value.selected)
      // this.handleSelectItem(option)
    }
  },
  created () {
    this.getDataMaterials()
  },
  watch: {
    reset (val) {
      this.selectedNameMaterial = ''
      this.$store.commit('unitPrice/setSelectedMaterials', [])
    },
    detailUnitPrice: {
      handler (val) {
        if (val.selected && this.idAutocomplete === `${val.index}-${val.code.replace(/^edit_/, '')}`) {
          this.selectedNameMaterial = arrayToName(val.selected)
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

.input-unit input {
  border-radius: 10px !important;
  border: 1px solid $color-grey-2 !important;
  font-size: 15px;
}

.input-unit.hide-border input {
  border: none !important;
}

.modal-select-material {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  border-radius: 5px;
  position: absolute;
  top: 44px;
  background: white;
  width: 100%;
  padding: 10px 0;
}

.wrap-body-modal-materials {
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;
}

.data-item-wrapper {
  height: 100%;

  > div {
    &:hover {
      background: #FFE5E5;
    }

    .input {
      width: 20px;
      height: 20px;
    }
  }

  .img-item-material {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.selected-material-name {
  max-height: 100%;
  overflow: auto;
}
</style>

<style>
.input-unit.unset-max-width .autocomplete .dropdown-menu {
  max-width: unset !important;
}
</style>
